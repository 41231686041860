import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { TESTIMONIAL_AVATAR, TESTIMONIAL_NAME } from 'lib/constants'

export function TestimonialPanel() {
  const { t } = useTranslation()
  return (
    <>
      <img
        style={{ borderRadius: '50%' }}
        width="150px"
        height="150px"
        src={TESTIMONIAL_AVATAR}
        alt="avatar"
      />
      <Stack sx={{ px: '5vw' }} spacing={2}>
        <Typography variant="h6" textAlign="center">
          {t('login-form.testimonial-quote')}
        </Typography>
        <Typography textAlign="center">
          {TESTIMONIAL_NAME} — {t('login-form.testimonial-occupation')}
        </Typography>
      </Stack>
    </>
  )
}
