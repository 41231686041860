import { isWhitelabel } from 'lib/whitelabelEnvs'

import { TestimonialPanel } from 'scenes/Authorization/components/TestimonialPanel'
import { WhitelabelSidePanel } from 'scenes/Authorization/components/WhitelabelSidePanel'

export function SignUpSecondPanel() {
  if (isWhitelabel()) {
    return <WhitelabelSidePanel />
  }
  return <TestimonialPanel />
}
