import { Typography } from '@mui/material'
import getConfig from 'next/config'
import Image from 'next/image'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { LOGO_URL } from 'lib/constants'

const {
  publicRuntimeConfig: { PLATFORM_NAME },
} = getConfig()

export function WhitelabelSidePanel() {
  const { t } = useTranslation()
  return (
    <>
      <Image
        alt={PLATFORM_NAME}
        width={400}
        height={200}
        style={{ objectFit: 'contain' }}
        src={LOGO_URL}
      />
      <Typography variant="h5" textAlign="center">
        {t('login-form.whitelabel-platform-description', { PLATFORM_NAME: PLATFORM_NAME })}
      </Typography>
    </>
  )
}
