import { GetServerSideProps } from 'next'
import getConfig from 'next/config'
import Head from 'next/head'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_APP_SUBDOMAIN } from 'lib/constants'
import { routes } from 'lib/routes'

import { LoginPage } from 'scenes/Authorization/LoginPage'
import { logout } from 'services/auth'
import {
  createPage,
  isAuthenticated,
  isCustomer,
  redirectBack,
  ServerUserProps,
} from 'services/auth/ssp'

const {
  publicRuntimeConfig: { PLATFORM_NAME },
} = getConfig()

type LoginProps = {
  force_logout?: boolean
}

const {
  publicRuntimeConfig: {
    featureFlags: { showGoogleAuthButton, showFacebookAuthButton },
  },
} = getConfig()

type PageProps = ServerUserProps & LoginProps
export const getServerSideProps: GetServerSideProps<PageProps> = async (ctx) => {
  return await createPage(ctx, {
    loadUser: true,
    allowedSubdomain: 'only-app',
    loadData: async (props) => {
      if (isAuthenticated(props)) {
        if (isCustomer(props)) {
          return { force_logout: true }
        }
      }
    },
    userConditions: async (props) => {
      if (isAuthenticated(props) && !isCustomer(props)) {
        return {
          redirect: { destination: redirectBack(ctx.query), permanent: false },
        }
      }
      if (!showFacebookAuthButton && !showGoogleAuthButton) {
        return { redirect: { destination: routes.login.withEmail, permanent: false } }
      }
    },
  })
}

function Page({ currentUrl, currentSubdomain, force_logout }: PageProps) {
  const { t } = useTranslation()
  const metaTitle = t('metadata.login.title', { PLATFORM_NAME: PLATFORM_NAME })
  const description = t('metadata.login.description', { PLATFORM_NAME: PLATFORM_NAME })

  useEffect(() => {
    if (force_logout) {
      logout(false)
      window.location.reload()
    }
  }, [])

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta key="og:title" property="og:title" content={metaTitle} />
        <meta key="description" name="description" content={description} />
        <meta key="og:description" property="og:description" content={description} />
        <link rel="canonical" href={currentUrl} />
      </Head>
      <LoginPage currentSubdomain={currentSubdomain ?? DEFAULT_APP_SUBDOMAIN} />
    </>
  )
}

export default Page
