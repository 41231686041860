import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SplitPaneLayout } from 'layout/SplitPaneLayout'
import { colors } from 'layout/themeColors/colors'
import { AuthorizationPanelTemplate } from 'scenes/Authorization/components/AuthorizationPanelTemplate'
import { SignUpSecondPanel } from 'scenes/Authorization/components/SignUpSecondPanel'

import { LoginButtons } from './components/LoginButtons'

const {
  publicRuntimeConfig: { PLATFORM_NAME },
} = getConfig()

type PageProps = {
  currentSubdomain: string
}

export const LoginPage = ({ currentSubdomain }: PageProps) => {
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <SplitPaneLayout
      firstPaneContent={
        <AuthorizationPanelTemplate
          title={t('login-form.welcome', { PLATFORM_NAME: PLATFORM_NAME })}>
          <LoginButtons
            afterAuth={() => router.push(router.query)}
            currentSubdomain={currentSubdomain}
          />
        </AuthorizationPanelTemplate>
      }
      secondPaneContent={
        <AuthorizationPanelTemplate bgcolor={colors.highlighter}>
          <SignUpSecondPanel />
        </AuthorizationPanelTemplate>
      }
    />
  )
}
