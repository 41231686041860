import { Stack, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
  title?: string
  bgcolor?: string
}

export function AuthorizationPanelTemplate({ children, title, bgcolor }: Props) {
  return (
    <>
      {title && (
        <Stack sx={{ flexGrow: 1, flexBasis: 0 }} justifyContent="center" alignItems="center">
          <Typography variant="h3" textAlign="center">
            {title}
          </Typography>
        </Stack>
      )}
      <Stack
        spacing={3}
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        sx={{ bgcolor, flexGrow: 1, flexBasis: 0 }}>
        {children}
      </Stack>
      {title && <Stack sx={{ flexGrow: 1, flexBasis: 0 }} />}
    </>
  )
}
