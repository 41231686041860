import { useRouter } from 'next/router'
import React from 'react'

import { routes } from 'lib/routes'

import { AuthButtons } from './AuthButtons'

type LoginButtonsProps = {
  afterAuth: () => void
  currentSubdomain: string
}

export const LoginButtons = ({ afterAuth, currentSubdomain }: LoginButtonsProps) => {
  const router = useRouter()

  return (
    <AuthButtons
      currentSubdomain={currentSubdomain}
      onEmailContinue={() => router.push({ pathname: routes.login.withEmail, query: router.query })}
      afterAuth={afterAuth}
    />
  )
}
