import { Box, Hidden, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { ReactNode } from 'react'

import { BaseBanner } from 'components/BaseBanner'
import { LanguageChanger } from 'components/LanguageChanger'

type Props = {
  bannerProps?: {
    showLanguageChangerInBanner: boolean
    height?: string
  }
  withBanner?: boolean

  firstPaneContent: ReactNode
  secondPaneContent: ReactNode
}

export function SplitPaneLayout({
  bannerProps = { showLanguageChangerInBanner: true, height: '70px' },
  firstPaneContent,
  secondPaneContent,
}: Props) {
  return (
    <Box height="100vh" width="100%">
      {bannerProps && (
        <BaseBanner height={bannerProps.height} bannerColor="#fff">
          {bannerProps.showLanguageChangerInBanner && (
            <Stack alignItems="end" width="100%">
              <LanguageChanger background="transparent" />
            </Stack>
          )}
        </BaseBanner>
      )}
      <Box height={bannerProps ? `calc(100% - ${bannerProps.height})` : '100%'}>
        <Grid2
          container
          columns={2}
          xs={1}
          md={2}
          justifyContent="center"
          height="100%"
          width="100%"
          sx={{ borderTop: `1px solid ${grey[300]}` }}>
          <Stack width={{ xs: '100%', md: '50%' }} alignItems="center" p={5}>
            {firstPaneContent}
          </Stack>
          <Hidden mdDown>{secondPaneContent}</Hidden>
        </Grid2>
      </Box>
    </Box>
  )
}
